<template>
    <div>
        <div 
            class="text-black mb-4 md:mb-6 rounded-lg"
            :class="isEditing ? 'bg-yellow-100 border border-yellow-300' : 'bg-yellow-200'">
            
            <div 
                v-if="isEditing"
                class="p-6">
                
                <TextareaAutosize 
                    v-model="noteEdit.content" 
                    :ref="'input_+'+note.noteId"
                    spellcheck="false"
                    style="background: none; padding: 0; font-size: 1rem; border: 0; outline: 0; color: #000; width: 100%;"
                />
                
                <div class="mt-4 md:mt-6 flex items-center">
                    <div class="flex-grow flex items-center text-black">
                        
                        <a 
                            class="button note link mr-4 border border-yellow-300"
                            @click="deleteNote()">
                            
                            <i 
                                class="fas fa-trash"
                                :title="$t('general.remove')"
                            />
                        </a>
                        
                        <div class=" text-sm opacity-25">
                            {{$t('general.updated')}} {{ note.updatedAt | moment('from') }}
                        </div>
                    </div>
                    <div class="flex-none buttons-wrapper">
                        <a 
                            @click="cancelEditingNote()"
                            class="button note link" >
                            
                            {{ $t('general.cancel') }}
                        </a>
                        
                        <button 
                            type="submit"
                            class="button note"
                            :class="{'loading' : loading == 'update'}"
                            @click="updateNote()">
                            
                            {{ $t('general.update') }}
                        </button>
                    </div>
                </div>
            </div>
            <div 
                v-else
                class="p-4 md:p-6 "
                @click="startEditingNote()">
                
                <div v-html="note.content.replace(/(?:\r\n|\r|\n)/g, '<br />')" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        note: {
            type: Object,
        }
    },
    
    data(){
        return {
            loading: null,
            isEditing: false,
            noteEdit: null,
        }
    },
    
    methods: {
        startEditingNote() {
            this.noteEdit = JSON.parse(
                JSON.stringify( this.note )
            );
            this.isEditing = true;
            
            this.$nextTick( () => {
                const elementToFocus = this.$refs['input_+'+this.note.noteId].$el;
                if (elementToFocus) {
                    elementToFocus.focus();
                }
            });
        },
        
        cancelEditingNote(){
            this.isEditing = false;
        },
        
        async updateNote(){
            try {
                this.loading = 'update';
                const note =  await this.$store.dispatch('updateNote', this.noteEdit);
                this.$notify({ type: 'success', text: this.$t('notes.savedNote') });
                this.isEditing = false;
            } 
            catch (e) {
                this.$notify( {type: 'error', text: this.$t('notes.couldNotSaveNote')} );
                console.error(e);
            }
            finally {
                this.loading = null;
            }
        },
        
        async deleteNote(){
            try {
                this.loading = 'delete';
                const note =  await this.$store.dispatch('deleteNote', this.noteEdit);
                this.$notify({ type: 'success', text: this.$t('notes.deletedNote') });
                this.isEditing = false;
            } 
            catch (e) {
                this.$notify({ 
                    type: 'error', 
                    title: 'Error', 
                    text: this.$t('customers.problemDeleting')
                });
                
                console.error(e);
            } 
            finally {
                this.loading = null;
            }
        },
    },
    
    // async mounted(){
    //     window.addEventListener('keyup', this.cancelEditingNote );
    // },
    // beforeDestroy() {
    //     window.removeEventListener('keyup', this.cancelEditingNote);
    // },
}
</script>

<style lang="scss" scoped>
    [contenteditable] {
        outline: 0px solid transparent;
    }
</style>
